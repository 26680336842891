import { render, staticRenderFns } from "./PublicProductsForm.vue?vue&type=template&id=35d8ff01&"
import script from "./PublicProductsForm.vue?vue&type=script&lang=js&"
export * from "./PublicProductsForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PublicProductPriceSelect: require('/home/runner/work/lareverde-shop/lareverde-shop/components/inputs/PublicProductPriceSelect.vue').default,SpinButton: require('/home/runner/work/lareverde-shop/lareverde-shop/components/inputs/SpinButton.vue').default,RegistrationBeforeOrderModal: require('/home/runner/work/lareverde-shop/lareverde-shop/components/modals/RegistrationBeforeOrderModal.vue').default})

import ApiHelper from '~/helpers/ApiHelper';

const apiHelper = new ApiHelper();

export default class BalanceService {
	axios;
	constructor(axios) {
		this.axios = axios;
	}

	list(pagination, filters, order) {
		const params = apiHelper.setQueryParams(pagination, filters, order);
		return this.axios.get('/admin/balance', { params: params });
	}

	getByCustomer(id = null) {
		if (id) {
			return this.axios.get(`admin/balance/user/${id}`);
		}
		return this.axios.get(`/balance/my-balance`);
	}

	readjustBalance(quarter) {
		return this.axios.get('/admin/balance/balance-readjustment?quarter=' + quarter);
	}
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CalendarIcon, InfoIcon } from 'vue-feather-icons';
import OrderService from '../../services/OrderService';
import ApiHelper from '../../helpers/ApiHelper';

export default {
	name: 'NextDeliveryDate',
	components: { CalendarIcon, InfoIcon },
	props: ['delivery_date', 'order_limit'],
	data() {
		return {
			orderService: new OrderService(this.$axios),
			apiHelper: new ApiHelper(),
		};
	},
	mounted() {},
};

import { render, staticRenderFns } from "./index.vue?vue&type=template&id=76bf27f4&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MyBalance: require('/home/runner/work/lareverde-shop/lareverde-shop/components/core/MyBalance.vue').default,NextDeliveryDate: require('/home/runner/work/lareverde-shop/lareverde-shop/components/core/NextDeliveryDate.vue').default,PublicProductsForm: require('/home/runner/work/lareverde-shop/lareverde-shop/components/forms/PublicProductsForm.vue').default,ShopClose: require('/home/runner/work/lareverde-shop/lareverde-shop/components/others/ShopClose.vue').default})

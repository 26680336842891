//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: 'PublicProductPriceSelect',
	props: {
		model: [Object],
		label: String,
		placeholder: String,
		disabled: Boolean,
		options: {
			type: Array,
			default() {
				return [];
			},
		},
		errors: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	data() {
		return {
			select: null,
		};
	},
	watch: {
		model: function (newValue) {
			this.select = newValue;
		},
		options: function (newValue) {
			this.options = newValue;
			if (!this.model) {
				this.select = {
					id: this.options[0].id,
					price: this.options[0].price,
					price_type_name: this.options[0].amount_type_name,
				};
			}
		},
	},
	computed: {
		state() {
			return this.errors == null || this.errors.length == 0 ? null : this.errors.length == 0;
		},
		invalidFeedback() {
			if (this.errors.length) {
				return this.errors[0];
			}
			return null;
		},
	},
	mounted() {
		if (this.model) {
			this.select = this.model;
		} else if (this.options.length) {
			this.select = {
				id: this.options[0].id,
				price: this.options[0].price,
				price_type_name: this.options[0].amount_type_name,
			};
		}
	},
	methods: {
		formatNumber(value) {
			let val = (value / 1).toFixed(2).replace('.', ',');
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
		},
	},
};

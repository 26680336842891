//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { KeyIcon, UserIcon } from 'vue-feather-icons';
export default {
	name: 'RegistrationBeforeOrderModal',
	components: { KeyIcon, UserIcon },
	methods: {
		show() {
			this.$refs.modal.show();
		},
		hide() {
			this.$refs.modal.hide();
		},
		confirm() {
			this.$emit('confirmed');
		},
	},
};

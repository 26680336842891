//
//
//
//
//
//
//
//
//
//
//

export default {
	name: 'ShopClose',
	components: {},
	props: ['close_shop_title', 'close_shop_text'],

	data() {
		return {};
	},

	mounted() {},
};

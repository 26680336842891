//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: 'SpinButton',
	props: {
		model: [Number, String],
		placeholder: String,
		price: Object,
		disabled: Boolean,
		errors: {
			type: Array,
			default() {
				return [];
			},
		},
		min: {
			type: Number,
			default() {
				return 0;
			},
		},
		max: {
			type: Number,
			default() {
				return 50;
			},
		},
	},
	data() {
		return {
			value: 0,
			step: 1,
		};
	},
	watch: {
		model: function (newValue) {
			this.value = newValue;
		},
		price: function (val) {
			this.step = val.price_type_name === 'Kg' ? 0.5 : 1;
		},
	},
	computed: {
		state() {
			return this.errors == null || this.errors.length == 0 ? null : this.errors.length == 0;
		},
		invalidFeedback() {
			if (this.errors.length) {
				return this.errors[0];
			}
			return null;
		},
	},
	mounted() {
		if (this.model) {
			this.value = this.model;
		}
	},
	methods: {
		onInput() {
			this.$emit('input', this.value);
		},
		onChange() {
			this.$emit('change', this.value);
		},
	},
};

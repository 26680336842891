//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BalanceService from '~/services/BalanceService';
import ApiHelper from '~/helpers/ApiHelper';
import { CreditCardIcon, InfoIcon } from 'vue-feather-icons';

export default {
	name: 'MyBalance',
	components: { CreditCardIcon, InfoIcon },
	data() {
		return {
			balanceService: new BalanceService(this.$axios),
			apiHelper: new ApiHelper(),
			balance: {
				wallet: null,
				contribution: null,
				required_balance: null,
			},
		};
	},
	methods: {
		getBalance() {
			this.balanceService
				.getByCustomer()
				.then((res) => {
					if (res.status === 200) {
						this.balance.wallet = res.data.wallet;
						this.balance.contribution = res.data.contribution;
						this.balance.required_balance = res.data.required_balance;
					}
				})
				.catch((error) => {
					this.loading = false;
					this.apiHelper.handleErrors(error, {}, this.$store);
				});
		},
		formatNumber(value) {
			let val = (value / 1).toFixed(2).replace('.', ',');
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
		},
	},
	mounted() {
		this.getBalance();
	},
};
